import React, {useEffect} from "react";
import test1 from "../../../assets/About/test1.jpeg"
import test2 from "../../../assets/About/test2.png"
import test3 from "../../../assets/About/test3.jpeg"
import test4 from "../../../assets/About/test4.jpeg"
import test5 from "../../../assets/About/test5.jpeg"
import test6 from "../../../assets/About/test6.jpeg"
import homeBG from "../../../assets/Home/5570863.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const Testimonial = () => {

    const backgroundImageStyle = {
        backgroundImage: `url("${homeBG}")`,
        backgroundSize: "cover",
      };

    useEffect (() => {
        Aos.init({duration:2000});
    },[]);

    return (
      <div class="py-16" style={backgroundImageStyle}>
<div class="min-w-screen min-h-screen flex items-center justify-center py-5">
    <div class="w-full px-5 py-16 md:py-24 text-gray-800">
        <div class="w-full max-w-6xl mx-auto">
            <div class="text-center max-w-xl mx-auto">
                <h1 class="text-6xl md:text-7xl font-bold mb-5 text-gray-600">What people <br/>are saying.</h1>
                <h3 class="text-xl mb-5 font-light">AID Prime Group | Facility Services commitment to excellence</h3>
                <div class="text-center mb-10">
                    <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                    <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                    <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                    <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                    <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                </div>
            </div>
            <div class="-mx-3 md:flex items-start">
                <div class="px-3 md:w-1/3">
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test1} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Jack Wilson</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>end of lease cleaning job was done with Isuru. Reliable and trustworthy service. Highly recommended for any cleaning services.<span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test3} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Liam Roberts</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span> Our office has never looked better since partnering with AID Prime Group for commercial cleaning. Their team consistently goes above and beyond, delivering a workspace that impresses both our employees and clients. The level of cleanliness they maintain has significantly improved the overall atmosphere.<span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                </div>
                <div class="px-3 md:w-1/3">
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test4} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Ethan Mitchell</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>AID Prime Group exceeded all my expectations with their residential cleaning service. The team was thorough, professional, and left my home sparkling clean. <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300 ">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test2} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Emily Anderson</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span> I can't express enough how pleased I am with AID Prime Group's commercial cleaning services. As a retail manager, maintaining a clean and welcoming space is crucial. Their team consistently delivers exceptional results, ensuring our store always looks its best. <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                </div>
                <div class="px-3 md:w-1/3">
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test5} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Oliver Thompson</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Using AID Prime Group cleaning service was a delightful experience. They were prompt, courteous, and incredibly thorough, leaving my space spotless and fresh. Their attention to detail and gentle care in cleaning surpassed my expectations. Highly recommend for anyone seeking a top-notch clean with a personal touch!<span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 transform hover:-translate-y-2 transition duration-300">
                        <div class="w-full flex mb-4 items-center">
                            <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img src={test6} alt=""/>
                            </div>
                            <div class="flex-grow pl-3">
                                <h6 class="font-bold text-sm uppercase text-gray-600">Noah Harris</h6>
                            </div>
                        </div>
                        <div class="w-full">
                            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>I was truly impressed by their attention to detail and dedication to ensuring every corner was addressed. I highly recommend their services to anyone seeking top-notch cleaning for their homes.<span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

)
}

        {/* <div class="py-16" style={backgroundImageStyle}>
        <div data-aos="fade-up"  class="xl:container m-auto px-6 text-gray-600  md:px-12 xl:px-6">
          <h2 class="mb-12 text-center text-2xl font-bold text-gray-800 md:text-4xl font-['Maven Pro'] font-normal leading-loose">
            What's our customers say
          </h2>
          <div class="grid gap-8 md:grid-rows-2 lg:grid-cols-2">
            <div
              class="row-span-2 rounded-3xl border border-gray-100 bg-gray-200  p-8 text-center shadow-2xl shadow-gray-600/10  transform hover:-translate-y-2 transition duration-300"
            >
              <div class="flex h-full flex-col justify-center space-y-4">
                <img
                  class="mx-auto h-20 w-20 rounded-full"
                  src={test3}
                  alt="user avatar"
                  height="220"
                  width="220"
                  loading="lazy"
                />
                <p class="md:text-xl">
                  <span class="font-serif">"</span> Our office has never looked better since partnering with AID Prime Group for commercial cleaning. Their team consistently goes above and beyond, delivering a workspace that impresses both our employees and clients. The level of cleanliness they maintain has significantly improved the overall atmosphere. <span class="font-serif">"</span>
                </p>
                <div>
                  <h6 class="text-lg font-semibold font-['Maven Pro'] font-normal leading-loose">Binara Prabashwara</h6>
                  <span class="text-xs text-gray-500">Marketing Manager</span>
                </div>
              </div>
            </div>
      
            <div class="rounded-3xl sm:flex sm:space-x-8 border border-gray-100 bg-gray-200 p-8 text-center shadow-2xl shadow-gray-600/10  transform hover:-translate-y-2 transition duration-300">
              <img
                class="mx-auto h-20 w-20 rounded-full"
                src={test2}
                alt="user avatar"
                height="220"
                width="220"
                loading="lazy"
              />
              <div class="mt-4 space-y-4 text-center sm:mt-0 sm:text-left">
                <p >
                  <span class="font-serif">"</span> AID Prime Group exceeded all my expectations with their residential cleaning service. The team was thorough, professional, and left my home sparkling clean. I was truly impressed by their attention to detail and dedication to ensuring every corner was addressed. I highly recommend their services to anyone seeking top-notch cleaning for their homes.

<span class="font-serif">"</span>
                </p>
                <div>
                  <h6 class="text-lg font-semibold font-['Maven Pro'] font-normal leading-loose">Tharusha Gallage</h6>
                  <span class="text-xs text-gray-500">UI/UX Engineer</span>
                </div>
              </div>
            </div>
            <div class="rounded-3xl sm:flex sm:space-x-8 border border-gray-100 bg-gray-200  p-8 text-center shadow-2xl shadow-gray-600/10  transform hover:-translate-y-2 transition duration-300">
              <img
                class="mx-auto h-20 w-20 rounded-full"
                src={test1}
                alt="user avatar"
                height="220"
                width="220"
                loading="lazy"
              />
              <div class="mt-4 space-y-4 text-center sm:mt-0 sm:text-left">
                <p>
                  <span class="font-serif">"</span> I can't express enough how pleased I am with AID Prime Group's commercial cleaning services. As a retail manager, maintaining a clean and welcoming space is crucial. Their team consistently delivers exceptional results, ensuring our store always looks its best. <span class="font-serif">"</span>
                </p>
                <div>
                  <h6 class="text-lg font-semibold font-['Maven Pro'] font-normal leading-loose">Thilini Herath</h6>
                  <span class="text-xs text-gray-500">Product Designer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
                              */} 


export default Testimonial;