import React from "react";
import Home from "../Pages/HomePage/HomePage";
import About from "../Pages/HomePage/about";
import Partners from "../Pages/HomePage/partnets";
import homeServicess from "../Pages/HomePage/services";
import Collection from "../Pages/HomePage/collection";
import testHome from "../Pages/HomePage/testhome";

const HomePage = () => {
    return (
        <>
        <Home/>
        <About/>
        <Collection/>
        <Partners/>
  
        </>
    )
}

export default HomePage;