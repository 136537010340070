import React, {useEffect} from "react";
import "./app.css"
import Aos from "aos";
import "aos/dist/aos.css";
import service1 from "../../../assets/Home/service1.jpg"
import service2 from "../../../assets/Home/service2.jpg"
import service3 from "../../../assets/Home/service3.jpg"
import service4 from "../../../assets/Home/service4.jpg"
import service5 from "../../../assets/Home/service5.jpg"
import service6 from "../../../assets/Home/service6.jpg"
import service7 from "../../../assets/Home/service7.jpg"

const Collection = () => {

  useEffect (() => {
    Aos.init({duration:2000});
},[]);

    return (
        <div class="relative overflow-hidden bg-white">
  <div class="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
    <div class="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      <div data-aos="zoom-out" class="sm:max-w-lg">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl ">Dependable service for a tidy space</h1>
        <p class="mt-4 text-xl text-gray-500">Let us be the defenders of your spaces, ensuring they not only survive but thrive against the odds of neglect and dirt.</p>
      </div>
      <div>
        <div data-aos="fade-up" class="mt-10 ">

          <div class="  lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl ">
            <div class="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
              <div class="flex items-center space-x-6 lg:space-x-8">
                <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8 ">
                  <div class="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service1} alt="" class="h-full w-full object-cover object-center "/>
                  </div>
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service2} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                </div>
                <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service3} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service4} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service5} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                </div>
                <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service6} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                  <div class="h-64 w-44 overflow-hidden rounded-lg transition duration-200 transform  hover:-translate-y-2 hover:shadow-2xl">
                    <img src={service7} alt="" class="h-full w-full object-cover object-center"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a href="#" class="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700">Our Services</a>
        </div>
      </div>
    </div>
  </div>
</div>
    )
}

export default Collection;


