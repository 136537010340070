import React from "react";
import ContactPageHome from "../Pages/ContactPage/ContactPageHome";
import Form from "../Pages/ContactPage/Form";
import Map from "../Pages/ContactPage/Map";
import Contact from "../Navigation/contact/Contact";

const ContactPage = () => {
    return (
       <>
        <ContactPageHome/>
        {/* <Form/> */}
        <Map/>
        <Contact/>
    
       </> 
    )
}

export default ContactPage;