import React, {useEffect} from "react";
import lawn from "../../../assets/lawnMowing/lawn.jpg"

const LawnHero = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
      <section
      style={{ backgroundImage: `url(${lawn})` }}
      className="relative bg-cover bg-center bg-no-repeat"
      >
        <div
          class="absolute inset-0 bg-green-200 bg-opacity-40 px-30 backdrop-blur-sm  "
        ></div>
      
        <div
          class="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
        >
          <div class="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h1 class="text-3xl font-extrabold sm:text-5xl text-green-100">
              Let us transform
      
              <strong class="block font-extrabold text-green-700"> your lawn together </strong>
            </h1>
      
            <p class="mt-4 max-w-lg sm:text-xl/relaxed">
            Ready to give your lawn the care it deserves? Contact AID Prime Group today, and let our expert team 
transform your outdoor space into a green paradise
            </p>

  
           
          </div>
        </div>
      </section> 
    )
}

export default LawnHero