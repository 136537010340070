import React from "react";
import contactHome from "../../../assets/sad.png"
import { useEffect } from "react";
import HomeBG from "../../../assets/contactbg.jpg"
import "./contact.css"

const ContactPageHome = () => {

    const backgroundImageStyle = {
        backgroundImage: `url("${HomeBG}")`,
        backgroundSize: "cover",
        opacity: 0.8,
      };

    useEffect(() => {
        window.scrollTo(0, 0)
        
      }, [])

    return (
      <>

       
       <div className="relative bg-opacity-40 " style={backgroundImageStyle}>
             <div className="bg-blue-700 bg-opacity-40 px-30 backdrop-blur-sm">
              
             <section>
              
       <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-36">
         <div class="flex flex-wrap items-center mx-auto max-w-7xl">
           <div class="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
             <div>
               <div class="relative w-full max-w-lg">
                 <div class="absolute top-0 rounded-full bg-indigo-300 -left-4 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
     
                 <div class="absolute rounded-full bg-blue-300 -bottom-24 right-20 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                 <div class="relative">
                   <img class="object-cover mt-12 object-center w-[48rem] md:-ml-4 lg:-ml-0 " alt="hero" src={contactHome}/>
                 </div>
               </div>
             </div>
           </div>
           
           <div class="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
             <h1 class="mb-8 text-4xl font-bold leading-none tracking-widest text-white font-['Maven Pro'] md:text-7xl lg:text-5xl">Let's embark on a Journey of Transformation and Immaculateness Together.</h1>
             <p class=" mb-8 text-xl leading-relaxed tracking-widest text-left font-['Maven Pro'] text-gray-200">Where every inquiry becomes an opportunity to weave cleanliness into the fabric of your spaces.</p>
     
             
           </div>
         </div>
       </div>
     </section>
             </div>
            </div>
      </>
     
                                        
    )
}

export default ContactPageHome;