import React, {useEffect} from "react";
import grass from "../../../assets/lawnMowing/grass.png"
import Aos from "aos";
import "aos/dist/aos.css";
import homeBG from "../../../assets/Home/gray.jpg"


const LawnService = () => {

  const backgroundImageStyle = {
    backgroundImage: `url("${homeBG}")`,
    backgroundSize: "cover",
  };

  useEffect (() => {
    Aos.init({duration:2000});
},[]);


  return (

    <div class="py-16" style={backgroundImageStyle}>
      <div class="xl:container m-auto px-6 text-gray-600 md:px-12 xl:px-16">
        <div class="lg:bg-green-50 dark:lg:bg-darker lg:p-16 rounded-[4rem] space-y-6 md:flex flex-row-reverse md:gap-6 justify-center md:space-y-0 lg:items-center">
          <div class="md:5/12 lg:w-1/2 ml-24">
            <img
              src={grass}
              alt="image"
              loading="lazy"
              width=""
              height=""
            />
          </div>
          <div class="md:7/12 lg:w-1/2">
            <h2 class="text-3xl font-bold text-gray-900 md:text-4xl ">
              Transform Your Lawn with AID Prime Group: Your Expert in Green Spaces
            </h2>
            <p class="my-8 text-gray-600 ">
              At AID Prime Group, we understand that a well-maintained lawn is not just an extension of your
              property; it's a reflection of your commitment to a healthy and aesthetically pleasing outdoor
              environment.
            </p>
            <div class="divide-y space-y-4 divide-gray-100 dark:divide-gray-800">
              <div class="mt-8 flex gap-4 md:items-center">
                <div class="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
          
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 m-auto text-blue-500">
                    <path d="M6 12a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 1 1 1.5 0v7.5A.75.75 0 0 1 6 12ZM18 12a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 1 1.5 0v7.5A.75.75 0 0 1 18 12ZM6.75 20.25v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0ZM18.75 18.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 1.5 0ZM12.75 5.25v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0ZM12 21a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 1 1.5 0v7.5A.75.75 0 0 1 12 21ZM3.75 15a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0ZM12 11.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5ZM15.75 15a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0Z" />
                  </svg>

                </div>
                <div class="w-5/6">
                  <h4 class="font-semibold text-lg text-blue-700 ">Enhanced Curb Appeal</h4>
                  <p class="text-gray-500"> A well-maintained lawn adds beauty and value to your property.</p>
                </div>
              </div>
              <div class="pt-4 flex gap-4 md:items-center">
                <div class="w-12 h-12 flex gap-4 rounded-full bg-teal-100 dark:bg-teal-900/20">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 m-auto text-teal-600 dark:text-teal-400">
                    <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="w-5/6">
                  <h4 class="font-semibold text-lg text-teal-500">Enjoyable Outdoor Space</h4>
                  <p class="text-gray-500 "> A manicured lawn provides the perfect setting for outdoor
                    activities..</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LawnService