import React, {useEffect} from "react";

import "./lawn.scss"
import Aos from "aos";
import "aos/dist/aos.css";
import homeBG from "../../../assets/Home/Art3.jpg"

const LawnDetails = () => {

  const backgroundImageStyle = {
    backgroundImage: `url("${homeBG}")`,
    backgroundSize: "cover",
  };

  useEffect (() => {
    Aos.init({duration:2000});
},[]);

    return (
        <div style={backgroundImageStyle}>
        <h2 class="text-center text-2xl font-bold text-gray-800 md:text-4xl">
        Why Choose AID Prime Group for Your  <br class="lg:block" hidden />
        Lawn Mowing Needs?
      </h2>

<div class="ag-format-container">
  <div class="ag-courses_box">
    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Expertise and Reliability
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
            Skilled Professionals
          </span>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Cutting-Edge Equipment
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
            Guranteed Precision
          </span>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Customized Lawn Care Plans
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
            Tailored Specific Needs
          </span>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Affordable Excellence
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
           Accessible Service
          </span>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Comprehensive Approach
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
            Precision Lawn Care
          </span>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        Spotless Finish
        </div>

        <div class="ag-courses-item_date-box">
          <span class="ag-courses-item_date">
           Edging and Cleanup
          </span>
        </div>
      </a>
    </div>

  </div>
</div>
</div>
    )
}

export default LawnDetails