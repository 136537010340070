import React from "react";
import LawnHero from "../Pages/LawnPage/lawnhero";
import LawnAbout from "../Pages/LawnPage/lawnabout";
import LawnService from "../Pages/LawnPage/lawnservices";
import LawnDetails from "../Pages/LawnPage/lawndetails";

const Lawnpage = () => {
    return (
        <>
        <LawnHero/>
        <LawnService/>
        <LawnAbout/>
        <LawnDetails/>
        </>
    )
}

export default Lawnpage;