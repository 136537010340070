import React, {useEffect} from "react";
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import Aos from "aos";
import "aos/dist/aos.css";
import "./home.css";

import homeBG from "../../../assets/Home/Art2.jpg"
const About = () => {
  
  const backgroundImageStyle = {
    backgroundImage: `url("${homeBG}")`,
    backgroundSize: "cover",
  };

  useEffect (() => {
    Aos.init({duration:2000});
},[]);


    return (
 <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" style={backgroundImageStyle}>
      <div data-aos="fade-up" className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
   
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-widest font-['Maven Pro'] font-semibold leading-loose text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
     
            <span className="relative">
            <h1 class="playful" aria-label="Wash your hands">
  <span aria-hidden="true">T</span><span aria-hidden="true">h</span><span aria-hidden="true">e</span>
  <span aria-hidden="true">Quality</span><span aria-hidden="true"> You </span><span aria-hidden="true">Deserve</span>
</h1>
            </span>
          </span>{' '}
           <br></br>   <h1 class="playful" aria-label="Wash your hands">
  <span aria-hidden="true">A</span><span aria-hidden="true"> Service </span><span aria-hidden="true">You </span>
  <span aria-hidden="true">can</span><span aria-hidden="true">Trust </span>
</h1>
        </h2>



        <p className="text-base font-['Maven Pro'] tracking-widest text-gray-700 md:text-lg">
        The foundation of our commitment to excellence. At AID Prime Group,
         we believe that every client deserves the highest standard of cleaning services delivered with unwavering reliability and integrity. 
        </p>
      </div>
      </div>
     
    )
}

export default About;