import React, {useEffect} from "react";

import cutting from "../../../assets/lawnMowing/cutting.jpg"
import edging from "../../../assets/lawnMowing/edging.jpg"
import trimming from "../../../assets/lawnMowing/trimming.jpg"
import debris from "../../../assets/lawnMowing/debris.jpg"
import Aos from "aos";
import "aos/dist/aos.css";
import homeBG from "../../../assets/Home/Art3.jpg"

const LawnAbout = () => {

  const backgroundImageStyle = {
    backgroundImage: `url("${homeBG}")`,
    backgroundSize: "cover",
  };

  useEffect (() => {
    Aos.init({duration:2000});
},[]);

    return (
      // data-aos="fade-up"
        <div class="py-20 " style={backgroundImageStyle}>
        <div class="xl:container mx-auto px-6 md:px-12">
          <div class="mb-16 md:w-2/3 lg:w-1/2">
            <h2 class="mb-4 text-2xl font-bold text-gray-800 dark:text-black md:text-4xl">
              Elevate Your Green Spaces with AID Prime Group
            </h2>
            <p class="text-gray-600 dark:text-black">
            A meticulously manicured lawn is not merely an aesthetic luxury; it's a testament to care, 
precision, and the embodiment of a healthy outdoor environment. Lawn mowing, a seemingly 
simple task, holds profound significance in maintaining the vitality and allure of your green 
spaces.
            </p>
          </div>
          <div class="grid gap-6 px-4 sm:px-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div class="group relative rounded-3xl  space-y-6 overflow-hidden">
              <img
                class="mx-auto h-[26rem] w-full  object-cover object-top ransition duration-500 group-hover:scale-105 "
                src={cutting}
                alt="woman"
                loading="lazy"
                width="640"
                height="805"
              />
              <div class="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 bg-green-700 translate-y-[9rem] transition duration-300 ease-in-out group-hover:translate-y-0">
                <div>
                  <h4 class="text-xl font-semibold  text-white">Precision Grass Cutting</h4>
                </div>
                <p class="mt-8 text-gray-300 "> 
                This service involves using professional-grade lawn mowers to cut the grass at an 
even height, ensuring a neat and uniform appearance
</p>
              </div>
              
            </div>
            <div class="group relative rounded-3xl  space-y-6 overflow-hidden">
              <img
                class="mx-auto h-[26rem] w-full object-cover object-top ransition duration-500 group-hover:scale-105"
                src={edging}
                alt="woman"
                loading="lazy"
                width="640"
                height="805"
              />
              <div class="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 bg-teal-700 translate-y-[9rem] transition duration-300 ease-in-out group-hover:translate-y-0">
                <div>
                  <h4 class="text-xl font-semibold  text-white">Edging for a Defined Landscape</h4>
                </div>
                <p class="mt-8 text-gray-300 ">
                    Edging is the process of creating clean and distinct lines along the borders of 
your lawn, walkways, and flower beds</p>
              </div>
              
            </div>
            <div class="group relative rounded-3xl  space-y-6 overflow-hidden">
              <img
                class="mx-auto h-[26rem] w-full object-cover object-top transition duration-500 group-hover:scale-105 "
                src={trimming}
                alt="woman"
                loading="lazy"
                width="640"
                height="805"
              />
              <div class="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 bg-green-700 translate-y-[9rem] transition duration-300 ease-in-out group-hover:translate-y-0">
                <div>
                  <h4 class="text-xl font-semibold text-white">Shrub and Hedge Trimming</h4>
                </div>
                <p class="mt-8 text-gray-300 ">
                    Shrub and hedge trimming involves carefully shaping and pruning the foliage of 
bushes, shrubs, and hedges</p>
              </div>
              
            </div>
            <div class="group relative rounded-3xl  space-y-6 overflow-hidden">
              <img
                class="mx-auto h-[26rem] w-full object-cover object-top ransition duration-500 group-hover:scale-105"
                src={debris}
                alt="woman"
                loading="lazy"
                width="640"
                height="805"
              />
              <div class="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 bg-teal-700 translate-y-[9rem] transition duration-300 ease-in-out group-hover:translate-y-0">
                <div>
                  <h4 class="text-xl font-semibold text-white">Debris Cleanup</h4>
                </div>
                <p class="mt-8 text-gray-300 ">
                    After mowing and trimming, the debris cleanup service involves thorough 
removal of grass clippings, leaves, and other organic matter.</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
                                          
    )
}

export default LawnAbout