import React, { useEffect } from "react";
import mission from "../../../assets/About/mission.jpeg"
import vision from "../../../assets/About/vission.jpeg"
import values from "../../../assets/About/values.jpeg"
import homeBG from "../../../assets/Home/5570863.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const MiniAbout = () => {

  const backgroundImageStyle = {
    backgroundImage: `url("${homeBG}")`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div >
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 " style={backgroundImageStyle}>
        <div className="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
          <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg hover:bg-blue-100 transition duration-300  select-none">
            <div class="flex justify-center -mt-16 md:justify-end">
              <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={vision} />
            </div>

            <h2 class="mt-2 text-xl font-semibold text-blue-700   md:mt-0">Vision</h2>

            <p class="mt-2 text-sm text-gray-600 text-justify">Experience the AID Prime Group difference today and let us transform your space into a cleaner, healthier, and more vibrant environment. We look forward to serving you with utmost professionalism and making a lasting impact on your cleaning needs.
            </p>

          
          </div>

          <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg hover:bg-blue-100 transition duration-300 select-none">
            <div class="flex justify-center -mt-16 md:justify-end">
              <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={mission} />
            </div>

            <h2 class="mt-2 text-xl font-semibold text-blue-700  md:mt-0">Mission</h2>

            <p class="mt-2 text-sm text-gray-600 text-justify">AID Prime Group is dedicated to transforming spaces into cleaner, healthier, and more vibrant environments. Our mission is to deliver unparalleled cleaning and facility services through our commitment to quality.
            </p>

           
          </div>

          <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg hover:bg-blue-100 transition duration-300 select-none">
            <div class="flex justify-center -mt-16 md:justify-end">
              <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={values} />
            </div>

            <h2 class="mt-2 text-xl font-semibold text-blue-700  md:mt-0">Values</h2>

            <p class="mt-2 text-sm text-gray-600 text-justify"> We uphold the highest standards of professionalism in all our interactions and services, ensuring that we deliver consistent, high-quality results with every task we undertake.
            </p>

      
          </div>

        </div>




      </div>

    </div>



  )
}

export default MiniAbout;